<div class="container" navScrollable (scrolled)="setScrollState($event)">
  <NavComponent title="Program" [isScrolled]="isScrolled"></NavComponent>
  <div class="destination-cards-banner" *ngIf="destinations.length != 0">
    <div
      class="destinations-scroll-content"
      [ngClass]="single ? 'single-content' : ''"
    >
      <p-card
        *ngFor="let dest of destinations"
        pRipple
        class="destination card"
        styleClass="p-card-shadow"
        [routerLink]="['destination/' + dest.id]"
        [ngClass]="single ? 'card-center' : 'card-list-item'"
      >
        <div
          class="card-bg"
          alt="Accommodation Image"
          [ngStyle]="{ 'background-image': 'url(' + dest.image + ')' }"
        ></div>
        <h3 [innerHTML]="dest.name"></h3>
      </p-card>
    </div>
  </div>
  <p-accordion class="info accordion">
    <p-accordionTab *ngFor="let info of infos">
      <ng-template pTemplate="header"
        ><h4>{{ info.title }}</h4></ng-template
      >
      <ng-template pTemplate="content"
        ><p [innerHTML]="info.text"></p
      ></ng-template>
    </p-accordionTab>
  </p-accordion>
</div>
