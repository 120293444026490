<!-- <div class="login-container">
    <img class="login-img" src="/assets/imgs/login.jpeg"/>

    <mat-card class="ios-notice card" *ngIf="card">
        <mat-card-content>
            <mat-card-title>iOS</mat-card-title>
            <mat-card-subtitle>Zur "Installation" der App folgende Schritte ausführen</mat-card-subtitle>
            <ol class="step-list">
                <li><p>Code kopieren:</p>
                <form class="sc-form">
                    <mat-form-field class="sc-field-fill">
                      <input matInput placeholder="Shortcode" [disabled]="loadingShortCode" [value]="shortCode">
                    </mat-form-field>
                    <button mat-button (click)="shortcodeCopy()" class="sc-copy"><mat-icon>content_copy</mat-icon></button>
                </form></li>
                <li><p>Unten auf <i>'Teilen'</i>: <img src="/assets/svg/iosshare.svg" height="24px" width="24px" /> klicken</p></li>
                <li><p><mat-icon>add_box</mat-icon> zum Homebildschirm hinzufügen auswählen</p></li>
                <li><p>Die App vom Homebildschirm laden und den Code einfügen!</p></li>
            </ol>
            <mat-card-actions class="card-actions">
                <button mat-button (click)="onNoCLick()">Fertig</button>oder
                <button mat-button routerLink="/" color="primary">Weiter als Website</button>
            </mat-card-actions>
        </mat-card-content>
    </mat-card>
</div> -->
<div class="login-container">
  <img class="login-img" src="/assets/imgs/login.jpeg" />

  <mat-card class="ios-notice card" *ngIf="card">
    <mat-card-content>
      <mat-card-title>iOS</mat-card-title>
      <mat-card-subtitle
        >To "install" the app on your device, follow these
        steps:</mat-card-subtitle
      >
      <ol class="step-list">
        <li>
          <p>Copy code:</p>
          <form class="sc-form">
            <mat-form-field class="sc-field-fill">
              <input
                matInput
                placeholder="Shortcode"
                [disabled]="loadingShortCode"
                [value]="shortCode"
              />
            </mat-form-field>
            <button mat-button (click)="shortcodeCopy()" class="sc-copy">
              <mat-icon>content_copy</mat-icon>
            </button>
          </form>
        </li>
        <li>
          <p>
            Click <i>'Share'</i> below:
            <img src="/assets/svg/iosshare.svg" height="24px" width="24px" />
          </p>
        </li>
        <li>
          <p>
            <mat-icon>add_box</mat-icon> Select "Install" or "Add to Home
            Screen"
          </p>
        </li>
        <li><p>Open the app from your homescreen and enter the code!</p></li>
      </ol>
      <mat-card-actions class="card-actions">
        <button mat-button (click)="onNoCLick()">Done</button>or
        <button mat-button routerLink="/" color="primary">
          Continue in Browser
        </button>
      </mat-card-actions>
    </mat-card-content>
  </mat-card>
</div>
