import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { RouterOutlet, ActivatedRoute } from '@angular/router';
import { ProviderService } from '../provider.service';
import { DateFormatterService } from '../date-formatter.service';
import { ToasterService } from '../toaster.service';
import { HttpErrorResponse } from '@angular/common/http';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Inject} from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

export interface DialogData {
  code: string;
}

@Component({
 selector: 'dialog-code',
 templateUrl: 'dialog-code.html',
})
export class DialogCode {

 constructor(
   public dialogRef: MatDialogRef<DialogCode>,
   @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

 onNoClick(): void {
   this.dialogRef.close();
 }

}

@Component({
  selector: 'app-home-component',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})


export class HomeComponent implements OnInit {

  trip = {
    title: '-',
    date: '-'
  };
  weather: any;
  titleImage = '';
  companyImageSource = '';
  projID: number;
  shortCode: string;
  notificationCount = 0;

  getData() {
    this.api.getProjectInfo().subscribe((data: any) => {
      this.trip.title = data.Project_Title;
      this.weather = {location: data.Weather_Location, lat: data.Weather_LAT, lon: data.Weather_LON};
      const dateStart = new Date(new Date(data.Project_Date_Start).getTime());

      if(data.Project_Date_End) {

        const dateEnd = new Date(new Date(data.Project_Date_End).getTime());
        this.trip.date = this.dateFormatter.formatDate(dateStart) + ' bis ' + this.dateFormatter.formatDate(dateEnd);

      } else {
        this.trip.date = this.dateFormatter.formatDate(dateStart);
      }
      this.titleImage = this.api.getApiRoot() + data.Title_Image;
      this.companyImageSource = this.api.getCompanyImageLink(data.Client);
    },
    (err: HttpErrorResponse) => {
      this.toaster.handleApiError();
      throw err;
    });
    this.checkPrecache();
    this.registerUnique()
  }

  private getNotificationCount = function(){
    this.api.getNotificationCount().subscribe((data: any) => {
      this.notificationCount = data.count ?? 0;
    });
  }

  registerInstall() {
    console.info('stat', 1);
    this.api.registerInstall().subscribe({
      next: (data: any) => {
        console.log("register", data);
      }, error: (err: HttpErrorResponse) => {
        console.error(err);
      }
    });
  }
  async registerUnique() {
    const swCache = await window.caches.open('nasw');
    const unique = await (await swCache.match('unique'))?.text();
    if(unique !== 'reg') {
      this.api.registerUnique().subscribe({
        next: (data: any) => {
          console.log("register", data);
          swCache.put('unique', new Response('reg'));
        }, error: (err: HttpErrorResponse) => {
          console.error(err);
        }
      });
    }
  }

  async checkPrecache() {
    if(this.route.snapshot.queryParams.pwa) {
      //const inAndCache = this.provider.isInstalled(true);
      //if(!inAndCache) {
      const swCache = await window.caches.open('nasw');
      const inAndCache = await (await swCache.match('cacheHealth'))?.text();
      if(inAndCache !== 'ok') {
        const swReg = await navigator.serviceWorker.ready;

        if(inAndCache !== 'faulty') {
          this.registerInstall();
        }
        //post message to SW to check precache health/availability, and if unavailable, fetch the manifest and pass to SW
    
        navigator.serviceWorker.addEventListener('message', (event) => {
          if(event.data.action === 'checkPrecache') {
            if(event.data.status === 'unavailable') {
              this.api.getCacheManifest();
            }
          }
        });
    
        navigator.serviceWorker.controller.postMessage({
          action: 'checkPrecache'
        });
      }
    }
  }

  constructor(private updates: SwUpdate, public dialog: MatDialog, private api: ApiService, private route: ActivatedRoute, private provider: ProviderService, private dateFormatter: DateFormatterService, private toaster: ToasterService) {
    /*this.route.params.subscribe( params => {
      //this.projID = params.id;
      //this.provider.updateRootId(this.projID);
    });*/

    this.route.data.subscribe( data => {
      this.provider.updateRouteDepth(data.depth);
    });

    //TODO deprecated
    /*this.updates.available.subscribe((event: any) => {
      this.updates.activateUpdate().then(() => {
        document.location.reload();
      });
    });*/
    navigator.serviceWorker.ready.then((swReg) => {
      navigator.serviceWorker.addEventListener('message', (event: any) => {
        if(event.data.type === 'bust') {
          document.location.reload();
        }
      });
    });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogCode, {
      width: '250px',
      data: {code: this.shortCode}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.shortCode = result;
      this.checkCode()
    });
  }

  checkCode() {
    this.api.checkShortcode(this.shortCode).subscribe({
      next: (data: any) => {
      this.provider.setToken(data.token);
      this.api.refreshToken();
      this.getData();
      }, error: (err: HttpErrorResponse) => {
      this.toaster.handleApiError();
    }});
  }

  async tknThenData() {

    /*if(navigator.serviceWorker.controller && navigator.serviceWorker.controller.state !== 'activated') {
      setTimeout(() => {
        this.tknThenData();
      }, 300);
      return;
    }*/
    await navigator.serviceWorker.ready;

    this.api.getTkn().then( (tkn) => {
      this.provider.setToken(tkn);
      this.api.refreshToken();
      this.getData();
      this.getNotificationCount();
    });
  }

  ngOnInit() {
    this.provider.setContentLoading(false);
    this.provider.setErrorLoading(false);
    if(this.provider.getToken()) {
      this.getData();
      this.getNotificationCount();
    } else {
      if(this.route.snapshot.queryParams.pwa) {
        //wait for token then check precache
        if(!this.provider.isIos()) this.tknThenData();
        else {
          this.openDialog();
        }
      }
    }
  }

}
