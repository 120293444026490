<h1 mat-dialog-title>Insert Code here:</h1>
<div mat-dialog-content>
  <mat-form-field>
    <input matInput [(ngModel)]="data.code" />
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="data.code" cdkFocusInitial>
    Submit
  </button>
</div>
