import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule, HttpClientJsonpModule } from "@angular/common/http";
import { NgModule, ErrorHandler, LOCALE_ID } from "@angular/core";

// Application imports
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";

// Angular Modules
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatCardModule } from "@angular/material/card";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatDialogModule } from "@angular/material/dialog";
import { MatInputModule } from "@angular/material/input";
import { FormsModule } from "@angular/forms";
import { ServiceWorkerModule } from "@angular/service-worker";
import { GoogleMapsModule } from "@angular/google-maps";

// Custom Components
import { HomeComponent, DialogCode } from "./home/home.component";
import { HomepillComponent } from "./homepill/homepill.component";
import { ProgramComponent } from "./program/program.component";
import { InfoComponent } from "./info/info.component";
import { TransportComponent } from "./transport/transport.component";
import { AccomodationComponent } from "./accomodation/accomodation.component";
import { MapComponent } from "./map/map.component";
import { NotificationComponent } from "./notification/notification.component";
import { NoticeComponent } from "./notice/notice.component";
import { AccDetailComponent } from "./acc-detail/acc-detail.component";
import { ProgramDetailComponent } from "./program-detail/program-detail.component";
import { DestinationDetailComponent } from "./destination-detail/destination-detail.component";
import { ToasterService } from "./toaster.service";
import { environment } from "../environments/environment";
import { LoginComponent } from "./login/login.component";
import { MenuBarComponent } from "./menu-bar/menu-bar.component";
import { ImageHeaderComponent } from "./image-header/image-header.component";
import { ProgramPreviewComponent } from "./program-preview/program-preview.component";
import { WeatherWidgetComponent } from "./weather-widget/weather-widget.component";
import { NavComponent } from "./nav/nav.component";
import { NavScrollableDirective } from "./nav-scrollable.directive";
import { GalleryHeaderComponent } from "./gallery-header/gallery-header.component";

// PrimeNG modules
import { ButtonModule } from "primeng/button";
import { CardModule } from "primeng/card";
import { RippleModule } from "primeng/ripple";
import { TimelineModule } from "primeng/timeline";
import { TabViewModule } from "primeng/tabview";
import { GalleriaModule } from "primeng/galleria";
import { AccordionModule } from "primeng/accordion";
import { FieldsetModule } from "primeng/fieldset";
import { DividerModule } from "primeng/divider";
import { PanelModule } from "primeng/panel";
import { DataViewModule } from "primeng/dataview";

// Other custom modules
import { WeatherNumPipe } from "./weather-num.pipe";
import { WeatherDatePipe } from "./weather-date.pipe";
import { WeatherIconPipe } from "./weather-icon.pipe";
import { WeatherBGPipe } from "./weather-bg.pipe";
import { TimeStampPipe } from "./time-stamp.pipe";

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    DialogCode,
    HomepillComponent,
    ProgramComponent,
    InfoComponent,
    TransportComponent,
    AccomodationComponent,
    MapComponent,
    NotificationComponent,
    NoticeComponent,
    AccDetailComponent,
    ProgramDetailComponent,
    DestinationDetailComponent,
    LoginComponent,
    NavComponent,
    MenuBarComponent,
    ImageHeaderComponent,
    ProgramPreviewComponent,
    WeatherWidgetComponent,
    WeatherNumPipe,
    WeatherDatePipe,
    WeatherIconPipe,
    WeatherBGPipe,
    NavScrollableDirective,
    GalleryHeaderComponent,
    TimeStampPipe,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    HttpClientJsonpModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatCardModule,
    MatInputModule,
    MatSnackBarModule,
    ServiceWorkerModule.register("sw.js", {
      //enabled: environment.production,
      enabled: true,
    }),
    ButtonModule,
    CardModule,
    RippleModule,
    TimelineModule,
    TabViewModule,
    GalleriaModule,
    AccordionModule,
    FieldsetModule,
    DividerModule,
    PanelModule,
    DataViewModule,
    GoogleMapsModule,
  ],
  entryComponents: [DialogCode],
  providers: [ToasterService, { provide: LOCALE_ID, useValue: "en" }],
  bootstrap: [AppComponent],
})
export class AppModule {}
