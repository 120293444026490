import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
  providedIn: "root",
})
export class ToasterService {
  static readonly DEFAULT_ERROR_TITLE: string = "We are sorry!";
  static readonly API_ERROR_MESSAGE: string =
    "An error occured, please try again later.";
  static readonly CONNECTION_ERROR_MESSAGE: string =
    "No connection could be established. Please check your internet connectivity and try again.";
  static readonly DEFAULT_ERROR_MESSAGE: string =
    "An error occured, please try again later.";
  static readonly ROUTE_ERROR_MESSAGE: string = "Invalid trip";
  static readonly NO_DATA_MESSAGE: string = "There are no entries available";

  constructor(private snackBar: MatSnackBar) {}

  popToast(title: string, text: string, action?: any) {
    if (action) {
      const snack = this.snackBar.open(text, "OK");
      snack.onAction().subscribe(action);
    } else {
      const snack = this.snackBar.open(text, undefined, { duration: 10000 });
    }
  }

  handleNetworkError() {
    this.popToast(
      ToasterService.DEFAULT_ERROR_TITLE,
      ToasterService.CONNECTION_ERROR_MESSAGE,
      () => {}
    );
  }

  handleApiError() {
    this.popToast(
      ToasterService.DEFAULT_ERROR_TITLE,
      ToasterService.API_ERROR_MESSAGE
    );
  }
}
