<ImageHeader
  [data]="{trip, companyImageSource, titleImage}"
  class="image-header"
></ImageHeader>

<div class="dashboard-wrapper">
  <ProgramPreview></ProgramPreview>
  <WeatherWidget [weather]="weather"></WeatherWidget>

  <div class="home-button-wrapper">
    <div class="home-button" pRipple routerLink="/infos">
      <i class="fa-solid fa-circle-info"></i>
      <span class="home-button-text">Infos</span>
    </div>
    <div class="home-button" pRipple routerLink="/map">
      <i class="fa-solid fa-map"></i>
      <span class="home-button-text">Maps</span>
    </div>
    <div class="home-button" pRipple routerLink="/notifications">
      <span *ngIf="notificationCount != 0" class="notification-badge">
        {{ notificationCount }}
      </span>
      <i class="fa-solid fa-message"></i>
      <span class="home-button-text">News</span>
    </div>
  </div>

  <img
    class="et-img"
    src="/assets/imgs/title-image.png"
    alt="Eurotravel Solutions"
  />
</div>
<!--div class="home-container">
    <div class="home-pills">
        <div class="title"> 
            <h3>{{ trip.title }} </h3>
            <p>{{ trip.date }} <p>
        </div>
        <HomepillComponent *ngFor="let pill of pills" [details]="pill" color="primary" [ngClass]="'grid-'+[pill.seq]">
            
        </HomepillComponent>
        <img src="{{ companyImageSource }}" class="company-image">
    </div>
    <a routerLink="notice" class="notice-icon"><i class="material-icons-outlined">info</i></a>
</div-->
