<div class="container">
  <div class="header">
    <div class="title-box">
      <h3>Imprint</h3>
    </div>
  </div>
  <p class="title"><strong>Eurotravel Solutions GmbH</strong></p>
  <p>
    Vogelweiderstraße 63<br />
    A-5020 Salzburg
  </p>
  <p>
    Phone: <a href="tel:+43662243603">+43 662 243603</a><br />
    Mail:
    <a href="mailto:info@eurotravel-solutions.com"
      >info@eurotravel-solutions.com</a
    >
  </p>
  <p>
    CEO: Mateja Bauer<br />
    UID: ATU66080037<br />
    Companies' Register Number: FN349253h
  </p>

  <p class="small">
    Responsible for the contents: Eurotravel Solutions GmbH<br />
    Subject to changes and errors.<br />
    Images: Eurotravel Solutions, istockphoto.com, fotolia.com
  </p>
  <p class="small">
    Für den Inhalt verantwortlich: Eurotravel Solutions GmbH<br />
    Änderungen und Irrtümer vorbehalten.<br />
    Bilder: Eurotravel Solutions, istockphoto.com, fotolia.com
  </p>
</div>
