<p-card class="program card" pRipple>
  <ng-template pTemplate="header">
    <div class="header-wrapper">
      <h3>Program:</h3>
      <h3 class="title-highlight">{{ meta.day | date : "EEEE" : "+0000" }}</h3>
    </div>
  </ng-template>

  <p-timeline [value]="day" align="left" class="program-timeline">
    <ng-template pTemplate="content" let-entry>
      <span [innerHTML]="entry.title"></span>
    </ng-template>
  </p-timeline>

  <ng-template pTemplate="footer">
    <button pButton type="button" class="p-button-text" routerLink="/program">
      Full program &nbsp;<i class="fa-solid fa-arrow-right"></i>
    </button>
  </ng-template>
</p-card>
