<div class="container" navScrollable (scrolled)="setScrollState($event)">
  <NavComponent title="Accommodation" [isScrolled]="isScrolled"></NavComponent>

  <p-accordion class="descriptor accordion" *ngIf="description.available">
    <p-accordionTab>
      <ng-template pTemplate="header"><h3>Overview</h3></ng-template>
      <ng-template pTemplate="content"
        ><p [innerHTML]="description.text"></p
      ></ng-template>
    </p-accordionTab>
  </p-accordion>

  <p-card
    [header]="acc.name"
    [subheader]="acc.address"
    *ngFor="let acc of accommodations"
    [routerLink]="['details/' + acc.id]"
    pRipple
    class="accommodation card"
    styleClass="p-card-shadow"
  >
    <ng-template pTemplate="header">
      <div
        alt="Accommodation Image"
        [ngStyle]="{ 'background-image': 'url(' + acc.image + ')' }"
      ></div>
    </ng-template>
    <p [innerHTML]="acc.prev"></p>
    <ng-template pTemplate="footer">
      <button
        pButton
        type="button"
        class="p-button-text"
        label="Details"
      ></button>
    </ng-template>
  </p-card>
</div>
