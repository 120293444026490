<div class="container" navScrollable (scrolled)="setScrollState($event)">
  <NavComponent title="Program" [isScrolled]="isScrolled"></NavComponent>
  <p-tabView
    [scrollable]="true"
    *ngIf="days.length > 0"
    (onChange)="handleChange($event)"
    [activeIndex]="activeTab"
  >
    <p-tabPanel *ngFor="let day of days; let i = index">
      <ng-template pTemplate="header">
        <strong> {{ day.title }} </strong>
        <span>{{ day.formattedDate }}</span>
      </ng-template>
      <ng-template pTemplate="content">
        <div class="entry-group" *ngIf="day.dawn.length != 0">
          <h5>Morning</h5>
          <p-timeline [value]="day.dawn" align="left" class="program-timeline">
            <ng-template pTemplate="marker" let-entry>
              <div
                class="p-timeline-event-marker"
                [class.detail-marker]="entry.detailId"
              ></div>
            </ng-template>
            <ng-template pTemplate="content" let-entry>
              <div class="day-entry-container">
                <div
                  class="entry-ripple"
                  pRipple
                  *ngIf="entry.detailId"
                  (click)="goDetail(entry.detailId)"
                ></div>
                <p class="day-entry" [innerHTML]="entry.title"></p>
                <i
                  class="detail-arrow fa-solid fa-arrow-right"
                  *ngIf="entry.detailId"
                  aria-hidden="true"
                ></i>
              </div>
            </ng-template>
          </p-timeline>
        </div>

        <div class="entry-group" *ngIf="day.morning.length != 0">
          <h5>Forenoon</h5>
          <p-timeline
            [value]="day.morning"
            align="left"
            class="program-timeline"
          >
            <ng-template pTemplate="marker" let-entry>
              <div
                class="p-timeline-event-marker"
                [class.detail-marker]="entry.detailId"
              ></div>
            </ng-template>
            <ng-template pTemplate="content" let-entry>
              <div class="day-entry-container">
                <div
                  class="entry-ripple"
                  pRipple
                  *ngIf="entry.detailId"
                  (click)="goDetail(entry.detailId)"
                ></div>
                <p class="day-entry" [innerHTML]="entry.title"></p>
                <i
                  class="detail-arrow fa-solid fa-arrow-right"
                  *ngIf="entry.detailId"
                  aria-hidden="true"
                ></i>
              </div>
            </ng-template>
          </p-timeline>
        </div>

        <div class="entry-group" *ngIf="day.noon.length != 0">
          <h5>Midday</h5>
          <p-timeline [value]="day.noon" align="left" class="program-timeline">
            <ng-template pTemplate="marker" let-entry>
              <div
                class="p-timeline-event-marker"
                [class.detail-marker]="entry.detailId"
              ></div>
            </ng-template>
            <ng-template pTemplate="content" let-entry>
              <div class="day-entry-container">
                <div
                  class="entry-ripple"
                  pRipple
                  *ngIf="entry.detailId"
                  (click)="goDetail(entry.detailId)"
                ></div>
                <p class="day-entry" [innerHTML]="entry.title"></p>
                <i
                  class="detail-arrow fa-solid fa-arrow-right"
                  *ngIf="entry.detailId"
                  aria-hidden="true"
                ></i>
              </div>
            </ng-template>
          </p-timeline>
        </div>

        <div class="entry-group" *ngIf="day.afternoon.length != 0">
          <h5>Afternoon</h5>
          <p-timeline
            [value]="day.afternoon"
            align="left"
            class="program-timeline"
          >
            <ng-template pTemplate="marker" let-entry>
              <div
                class="p-timeline-event-marker"
                [class.detail-marker]="entry.detailId"
              ></div>
            </ng-template>
            <ng-template pTemplate="content" let-entry>
              <div class="day-entry-container">
                <div
                  class="entry-ripple"
                  pRipple
                  *ngIf="entry.detailId"
                  (click)="goDetail(entry.detailId)"
                ></div>
                <p class="day-entry" [innerHTML]="entry.title"></p>
                <i
                  class="detail-arrow fa-solid fa-arrow-right"
                  *ngIf="entry.detailId"
                  aria-hidden="true"
                ></i>
              </div>
            </ng-template>
          </p-timeline>
        </div>

        <div class="entry-group" *ngIf="day.evening.length != 0">
          <h5>Evening</h5>
          <p-timeline
            [value]="day.evening"
            align="left"
            class="program-timeline"
          >
            <ng-template pTemplate="marker" let-entry>
              <div
                class="p-timeline-event-marker"
                [class.detail-marker]="entry.detailId"
              ></div>
            </ng-template>
            <ng-template pTemplate="content" let-entry>
              <div
                class="day-entry-container"
                (click)="goDetail(entry.detailId)"
              >
                <div
                  class="entry-ripple"
                  pRipple
                  *ngIf="entry.detailId"
                  (click)="goDetail(entry.detailId)"
                ></div>
                <p class="day-entry" [innerHTML]="entry.title"></p>
                <i
                  class="detail-arrow fa-solid fa-arrow-right"
                  *ngIf="entry.detailId"
                  aria-hidden="true"
                ></i>
              </div>
            </ng-template>
          </p-timeline>
        </div>
      </ng-template>
    </p-tabPanel>
  </p-tabView>
</div>
